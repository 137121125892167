import React from "react";
import "../utilCSS/commonFooter.css"
import Footer from "../utilIMG/commonFooter.svg";


function CommonFooter(){

    return(
        <div id="Footer">
            <img src={Footer}/> 
         </div>
    )

}
export default CommonFooter;